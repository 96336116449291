import React from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import color from '@/constants/color'
import { mq } from '@/components/media/media'
import { HeaderNav } from '@/components/section/header/header-nav'
import { headerNavList } from '@/constants/header'
import { FooterNav } from '@/components/section/footer/footer-nav'
import { FooterBottom } from '@/components/section/footer/footer-bottom'
import { footerBottomList } from '@/constants/footer'
import { navList } from '@/constants/footer'
import { Contact } from '@/components/top/contact'
import { Recruit } from '@/components/top/recruit'
import { OurService } from '@/components/top/ourService'
import { Investments } from '@/components/top/investments'
import { News } from '@/components/top/news'
import { KV } from '@/components/top/kv/kv'
import { Head } from '@/components/head'
import { MicrocmsDataType, NewsType, ServiceType } from '@/typedir/types'
import { tempNewsList } from '@/constants/news'
import logo_relic_holdings from '@/images/logo_relic_holdings.svg'

export const query = graphql`
  query {
    allMicrocmsNews {
      edges {
        node {
          date
          text
          url
        }
      }
    }
    allMicrocmsServices {
      edges {
        node {
          serviceName
          title
          description
          image {
            url
          }
          url
        }
      }
    }
  }
`

export default function Home({ data }: MicrocmsDataType): JSX.Element {
  const newsList: NewsType[] = [
    ...tempNewsList,
    ...data.allMicrocmsNews.edges.map((news) => {
      const { date, text, url } = news.node
      const formattedDate = date
        .slice(0, 10)
        .replace('-', '.')
        .replace('-', '.')
      return { date: formattedDate, text: text, url: url }
    })
  ]

  const serviceList: ServiceType[] = data.allMicrocmsServices.edges.map(
    (service) => {
      const { serviceName, title, description, image, url } = service.node
      return {
        serviceName: serviceName,
        title: title,
        description: description,
        image: image,
        url: url
      }
    }
  )

  return (
    <>
      <Head title="株式会社Relicホールディングス" url="" />
      <HeaderNav
        css={cssHeaderNav}
        url="/"
        imgSrc={logo_relic_holdings}
        alt="Relic HOLDINGS"
        navList={headerNavList}
        gradientColor={[color.blue1, color.blue2]}
        btnFontStyleCss={cssBtnFontStyle}
        arrowFillColor={color.white1}
        headerLogoCss={cssHeaderLogoSize}
      />
      <KV />
      <News newsList={newsList} />
      <OurService serviceList={serviceList} />
      <Investments />
      <Recruit />
      <Contact />
      <div
        css={css`
          border-top: 1px solid ${color.gray1};
        `}
      >
        <FooterNav navList={navList} />
        <FooterBottom
          copyrightLayoutCss={cssFooterBottom}
          footerBottomList={footerBottomList}
          copy="© Copyright 2021 Relic Holdings Inc. All rights reserved."
        />
      </div>
    </>
  )
}

const cssHeaderNav = css`
  padding: 9px 16px;
  box-shadow: 0 3px 6px ${color.black3};
  ${mq.pc} {
    box-shadow: 0 2px 12px ${color.black3};
  }
`

const cssHeaderLogoSize = css`
  width: 40px;
  ${mq.pc} {
    width: 65px;
  }
`

const cssBtnFontStyle = css`
  color: ${color.white1};
`

const cssFooterBottom = css`
  padding: 24px 16px 12px;
`
