import React from 'react'
import { css } from '@emotion/react'
import Slider from 'react-slick'

import { mq } from '@/components/media/media'
import { ServiceCard } from '@/components/common/card'
import settings from '@/constants/slickSettings'

import { ServiceType } from '@/typedir/types'

import { cssH2, GrayBG2 } from '@/constants/utils'

import gray_bg_02 from '@/images/gray_bg_02.jpg'
import {
  newCFNW,
  campfire,
  scalehack,
  rebootus,
  RUFU,
  mealforward,
  thirdeconomy,
  pro3lab,
  altilan
} from '@/constants/services'
import { MoreDetail } from '@/components/common/card'

type Props = {
  serviceList: ServiceType[]
}

export const OurService = (props: Props): JSX.Element => {
  const { serviceList } = props

  const [relic, _cfnw, ...list] = serviceList // eslint-disable-line
  const array = [
    relic,
    campfire,
    scalehack,
    rebootus,
    mealforward,
    thirdeconomy,
    newCFNW,
    ...list,
    RUFU,
    pro3lab,
    altilan
  ]

  return (
    <section css={cssOurServiceLayout}>
      <h2 css={[cssH2, cssTitleWrapper]}>
        <span>OUR SERVICE</span>
        <br />
        <span>グループ企業/サービス一覧</span>
      </h2>
      <div className="service-container">
        <div css={GrayBG2}>
          <img src={gray_bg_02} alt="" />
        </div>
        <Slider {...settings}>
          {array.map((service, index) => {
            return (
              <ServiceCard
                MoreDetail={<MoreDetail />}
                key={index}
                service={service}
              />
            )
          })}
        </Slider>
      </div>
    </section>
  )
}

const cssOurServiceLayout = css`
  position: relative;
  padding: 60px 0;
  ${mq.pc} {
    padding: 180px 0 0;
    margin-bottom: 180px;
  }
`

const cssTitleWrapper = css`
  padding: 0 16px;
  margin-bottom: 92px;
`
