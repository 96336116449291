import { NewsType } from '@/typedir/types'

export const tempNewsList: NewsType[] = [
  {
    date: '2024.01.25',
    text: '沖縄でスタートアップスタジオ事業やエンジニア教育事業を展開する株式会社Re:Buildを子会社化',
    url: 'https://prtimes.jp/main/html/rd/p/000000240.000016318.html'
  },
  {
    date: '2024.01.15',
    text: '100個の新産業の共創を目指す「新産業のエコシステムビルダー」であるSUNDRED株式会社と資本業務提携',
    url: 'https://prtimes.jp/main/html/rd/p/000000236.000016318.html'
  },
  {
    date: '2023.12.21',
    text: 'Relicホールディングス、グループ全10社の本社を業容拡大に伴い移転',
    url: 'https://prtimes.jp/main/html/rd/p/000000230.000016318.html'
  },
  {
    date: '2023.12.11',
    text: 'Relic、第18回ニッポン新事業創出大賞 アントレプレナー部門において「特別賞」を受賞',
    url: 'https://prtimes.jp/main/html/rd/p/000000223.000016318.html'
  },
  {
    date: '2023.12.07',
    text: 'ＮＴＴデータ社と新規事業の立ち上げから成長・拡大まで一気通貫に支援する業務提携を開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000222.000016318.html'
  },
  {
    date: '2023.12.05',
    text: '業界初の完全成果報酬型エンジニアリングを専門にWebアプリなどの新規ソフトウェア開発を行う「株式会社Pro3Lab」を設立',
    url: 'https://prtimes.jp/main/html/rd/p/000000220.000016318.html'
  },
  {
    date: '2023.10.26',
    text: 'ハードウェアとSaaSを掛け合わせた新規事業開発を支援するソリューション「Hardware SaaS Lab」を提供開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000211.000016318.html'
  },
  {
    date: '2023.09.28',
    text: '東京都のスタートアップ支援展開事業「TOKYO SUTEAM」に採択された学生特化の全方位型スタートアップスタジオ「ZERO1000 Ventures for student」が始動',
    url: 'https://prtimes.jp/main/html/rd/p/000000205.000016318.html'
  },
  {
    date: '2023.09.27',
    text: '先端テクノロジー研究開発専門組織を発足・研究開発によって蓄積されたナレッジを活用し、先端テクノロジーや市場構造変化を起点とした開発支援を行うソリューション「EIL」提供開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000202.000016318.html'
  },
  {
    date: '2023.09.26',
    text: '無人コンビニ「TukTuk」、オフィス向けに冷凍お弁当プランを提供開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000203.000016318.html'
  },
  {
    date: '2023.09.19',
    text: '国内初のトークン化債権のマーケットプレイスの提供を目指す「株式会社3rd Economy」を設立',
    url: 'https://prtimes.jp/main/html/rd/p/000000200.000016318.html'
  },
  {
    date: '2023.05.31',
    text: 'Relicが提供する国内シェアNo.1のイノベーションマネジメント・プラットフォーム『Throttle』にChatGPTを活用した「事業アイデア創出AI」をトライアル実装',
    url: 'https://prtimes.jp/main/html/rd/p/000000174.000016318.html'
  },
  {
    date: '2023.05.23',
    text: 'Relic、新規事業開発の各フェーズにおけるChatGPTの活用方法をまとめたホワイトペーパーを公開',
    url: 'https://prtimes.jp/main/html/rd/p/000000171.000016318.html'
  },
  {
    date: '2023.05.18',
    text: 'Relicホールディングス、スタートアップスタジオ「ZERO1000 Ventures」の第2号案件として、食の流通と購買のイノベーションを実現する「株式会社MEAL FORWARD」を設立',
    url: 'https://prtimes.jp/main/html/rd/p/000000170.000016318.html'
  },
  {
    date: '2023.04.05',
    text: '事業共創カンパニーのRelic、元フューチャーベンチャーキャピタル代表の松本直人氏をCVC支援アドバイザーとして招聘し、CVC支援事業を開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000163.000016318.html'
  },
  {
    date: '2023.03.29',
    text: 'Relic、遠州地方のイノベーション創出に向けた起業家の発掘・育成のため、静岡県浜松市・浜松イノベーションキューブに「Co-Creation Lab HAMAMATSU」を設立',
    url: 'https://prtimes.jp/main/html/rd/p/000000165.000016318.html'
  },
  {
    date: '2023.03.16',
    text: 'Relic、北海道函館市と連携協定を締結し、イノベーター人材の発掘・育成とイノベーション創出に向け、五稜郭フコク生命ビルに「Hakodate Innovation Port」を設立',
    url: 'https://prtimes.jp/main/html/rd/p/000000160.000016318.html'
  },
  {
    date: '2023.03.15',
    text: '株式会社Relicがアジア太平洋地域における急成長企業ランキング2023」に選出されました',
    url: 'https://prtimes.jp/main/html/rd/p/000000161.000016318.html'
  },
  {
    date: '2023.02.15',
    text: 'Relic、遠州地方のイノベーター人材発掘・育成とイノベーション創出に向け、静岡県浜松市・浜松アクトタワーに「Innovation Cross HAMAMATSU」を設立',
    url: 'https://prtimes.jp/main/html/rd/p/000000158.000016318.html'
  },
  {
    date: '2023.01.12',
    text: 'Relicホールディングス、連続的・同時多発的にスタートアップを創出するスタートアップスタジオ「Relic Startup Studio : ZERO1000 Ventures」を始動',
    url: 'https://prtimes.jp/main/html/rd/p/000000140.000016318.html'
  },
  {
    date: '2023.01.10',
    text: 'Relic、東海地方のイノベーター人材発掘・育成とイノベーション創出に向け、愛知県名古屋市・ミッドランドスクエアに「Nagoya Incubation Studio」を設立',
    url: 'https://prtimes.jp/main/html/rd/p/000000144.000016318.html'
  },
  {
    date: '2022.12.20',
    text: '提供を開始したタイムマシン経営型事業アイデア創出サービス 「IDEATION Cloud」が日経クロステックに掲載されました',
    url: 'https://xtech.nikkei.com/atcl/nxt/news/18/14346/'
  },
  {
    date: '2022.12.20',
    text: 'Relic、タイムマシン経営型事業アイデア創出サービス 「IDEATION Cloud」の提供を開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000125.000016318.html'
  },
  {
    date: '2022.12.06',
    text: 'CAMPFIRE ENjiNEがエイベックス・クリエイター・エージェンシーと業務提携し、エンタテインメント業界におけるクラウドファンディングの活用を加速',
    url: 'https://prtimes.jp/main/html/rd/p/000000139.000016318.html'
  },
  {
    date: '2022.11.29',
    text: 'Relic、新規事業のUIUXとブランディングに特化したデザインコンサルティング会社SEESAWに出資し、資本業務提携契約を締結',
    url: 'https://prtimes.jp/main/html/rd/p/000000138.000016318.html'
  },
  {
    date: '2022.10.26',
    text: 'Relic、コミュニティアプリ「Tailor Works」を展開する株式会社テイラーワークスに出資し、資本業務提携契約を締結',
    url: 'https://prtimes.jp/main/html/rd/p/000000131.000016318.html'
  },
  {
    date: '2022.09.01',
    text: '不動産投資型クラウドファンディング構築サービス「ENjiNE」を提供する事業共創カンパニーのRelicが、「クラファンフェスタ powered by Relic 2022」を開催',
    url: 'https://prtimes.jp/main/html/rd/p/000000129.000016318.html'
  },
  {
    date: '2022.08.31',
    text: 'Relic、中国地方のイノベーター人材発掘・育成とイノベーション創出に向け、島根県松江市に「松江イノベーションスクエア」を設立',
    url: 'https://prtimes.jp/main/html/rd/p/000000128.000016318.html'
  },
  {
    date: '2022.08.23',
    text: 'Relicは「PHP Conference Okinawa 2022」にPlatinumスポンサーとして協賛します',
    url: 'https://note.com/relic_tech/n/n7117b2148018'
  },
  {
    date: '2022.08.04',
    text: 'Relic、SaaSに特化したプロダクト共創型エンジニアリング「Digital Innovation Studio for SaaS」を提供開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000124.000016318.html'
  },
  {
    date: '2022.08.02',
    text: 'Relicホールディングスの戦略子会社Scalehackが、Webマーケティングの大衆化を目指すベーシックと業務提携し、BtoBマーケティングを総合的に支援するオールインワンソリューションの提供を開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000126.000016318.html'
  },
  {
    date: '2022.06.30',
    text: 'Relic、AWSパートナーネットワーク（APN）にてセレクトティア認定を取得',
    url: 'https://prtimes.jp/main/html/rd/p/000000117.000016318.html'
  },
  {
    date: '2022.06.20',
    text: 'Relic、コレクタブルNFTを展開するシンガポール発のスタートアップ「W3 Fortune PTE. LTD.」に出資し、資本業務提携契約を締結',
    url: 'https://prtimes.jp/main/html/rd/p/000000118.000016318.html'
  },
  {
    date: '2022.06.02',
    text: 'クラウドファンディングサイト「CAMPFIRE ENjiNE」がオープン！CAMPFIREとENjiNEネットワークの連携開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000116.000016318.html'
  },
  {
    date: '2022.04.18',
    text: 'Relicホールディングスが、事業再生や再成長を支援する「リノベーションスタジオ事業」及び事業のM&A・売買や再配置を支援する「リロケーションスタジオ事業」を展開する戦略子会社「Reboo＋」を設立',
    url: 'https://prtimes.jp/main/html/rd/p/000000113.000016318.html'
  },
  {
    date: '2022.04.05',
    text: '日経ビジネスにて弊社代表・北嶋の連載「VUCA時代を生き抜くための新規事業開発マネジメント」がスタートしました',
    url: 'https://business.nikkei.com/atcl/gen/19/00443/'
  },
  {
    date: '2022.04.05',
    text: 'Relicが日経ビジネス「日本急成長企業2022 売上高を伸ばした100社ランキング」に選出されました',
    url: 'https://business.nikkei.com/atcl/seminar/19/00030/032500343/'
  },
  {
    date: '2022.03.24',
    text: 'Relic、スタートアップスタジオ事業やエンジニア教育事業RUNTEQを展開する株式会社スタートアップテクノロジーに出資し、資本業務提携契約を締結',
    url: 'https://prtimes.jp/main/html/rd/p/000000111.000016318.html'
  },
  {
    date: '2022.03.22',
    text: 'RelicがFinancial Times社「アジア太平洋地域における急成長企業ランキング2022」に選出されました',
    url: 'https://prtimes.jp/main/html/rd/p/000000107.000016318.html'
  },
  {
    date: '2022.03.16',
    text: 'Relic、社会インパクト投資のプラットフォーム構築を目指すインパクトサークル株式会社に出資し、資本業務提携契約を締結',
    url: 'https://prtimes.jp/main/html/rd/p/000000106.000016318.html'
  },
  {
    date: '2022.03.09',
    text: 'Relicホールディングスが、事業の急拡大や持続的成長のためのスケールアップスタジオを展開する戦略子会社「Scalehack」を設立',
    url: 'https://prtimes.jp/main/html/rd/p/000000105.000016318.html'
  },
  {
    date: '2021.01.05',
    text: 'Relicホールディングスが国内最大のクラウドファンディング「CAMPFIRE」との合弁会社「CAMPFIRE ENjiNE」を設立',
    url: 'https://prtimes.jp/main/html/rd/p/000000095.000016318.html'
  },
  {
    date: '2021.12.15',
    text: 'Relicが提供する国内シェアNo.1のSaaS型イノベーションマネジメント・プラットフォーム「Throttle」が『Ruby biz Grand prix 2021』でDX賞を受賞',
    url: 'https://prtimes.jp/main/html/rd/p/000000094.000016318.html'
  },
  {
    date: '2021.11.30',
    text: 'Relic、BtoBスタートアップに特化してPMFやグロースまで成果報酬型や出資を含む多様なスキームで共創する「Growth Partner for Startup」を提供開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000092.000016318.html'
  },
  {
    date: '2021.11.09',
    text: 'Relic、内閣府・経済産業省・ジェトロと海外トップアクセラレーターが提携してグローバル展開を加速する「スタートアップシティ・アクセラレーションプログラム」に採択',
    url: 'https://prtimes.jp/main/html/rd/p/000000091.000016318.html'
  },
  {
    date: '2021.10.28',
    text: 'Relicの国内シェアNo.1イノベーションマネジメント・プラットフォーム「Throttle」が経済産業省「IT導入補助金2021」の対象ツールに採択',
    url: 'https://prtimes.jp/main/html/rd/p/000000089.000016318.html'
  },
  {
    date: '2021.10.20',
    text: 'Relicが和歌山県と進出協定を締結し、史上初となる和歌山市・白浜町の2拠点同時設立により全国展開を加速',
    url: 'https://prtimes.jp/main/html/rd/p/000000088.000016318.html'
  },
  {
    date: '2021.09.28',
    text: 'Relic、電通デジタル社と業務提携し、クラウドファンディングを活用した統合マーケティング支援を提供開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000085.000016318.html'
  },
  {
    date: '2021.09.21',
    text: '株式会社Relicホールディングスの設立及び持株会社体制への移行に関するお知らせ',
    url: 'https://prtimes.jp/main/html/rd/p/000000084.000016318.html'
  },
  {
    date: '2021.09.17',
    text: 'ProductZine「机上のアイデアを価値あるプロダクトへと昇華させる”プロトタイピング”のすすめ」にて、弊社CCO黒木の寄稿記事が掲載されました',
    url: 'https://productzine.jp/article/detail/625'
  },
  {
    date: '2021.09.09',
    text: 'DXマガジン記事「イノベーションは“一発必中”で起こそうとするのではなく、再現性を高める環境づくりに目を向けよ」にて、弊社代表北嶋のインタビューが掲載されました',
    url: 'https://dxmagazine.jp/DX%E3%82%A4%E3%83%B3%E3%82%BF%E3%83%93%E3%83%A5%E3%83%BC/%E5%AE%9F%E8%B7%B5%E8%80%85%E3%82%A4%E3%83%B3%E3%82%BF%E3%83%93%E3%83%A5%E3%83%BC/UoB8i'
  },
  {
    date: '2021.09.08',
    text: 'Relicの国内シェアNo.1クラウドファンディング構築SaaS「CROWDFUNDING NETWORK Powered by ENjiNE」、経済産業省「IT導入補助金2021」の対象ツールに採択',
    url: 'https://prtimes.jp/main/html/rd/p/000000081.000016318.html'
  }
]
